<template>
	<div class="p-content">
		<div class="top-brand d-flex justify-content-start align-items-center">
			<b-img class="logo-img" :src="appLogoImage" style="filter: brightness(1.5)" />
			<h2 class="mb-0 ml-2 mr-1 brand-text text-nowrap">Smart Rooster Bureau</h2>
		</div>
		<div class="p-content-left">
			<b-img class="p-content-brand" :src="appLogoGalpon" alt="Login V2" />
		</div>
		<div class="p-content-right">
			<div class="m-content-auth">
				<h2 class="m-auth-title text-custom">Bienvenido a Smart Rooster Bureau!</h2>
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
export default {
	name: "AuthLayout",
	setup() {
		const { appLogoGalpon, appLogoImage } = $themeConfig.app;
		return {
			appLogoGalpon,
			appLogoImage,
		};
	},
};
</script>

<style lang="scss">
:root {
	--sidebar-width: 30vw;
	--color-warning: rgb(255, 85, 42);
}
.p-content {
	display: flex;
	width: 100%;
	justify-content: space-between;
	max-height: 100vh;
	font-family: "Montserrat", Helvetica, Arial, serif;
}
.p-content-left {
	padding: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	width: calc(100vw - var(--sidebar-width));
}
.p-content-right {
	background-color: #283046;
	height: 100vh;
	display: flex;
	align-items: center;
	padding: 4rem;
	width: var(--sidebar-width);
}

.top-brand {
	position: absolute;
	top: 2rem;
	left: 2rem;
	color: #7367f0;
}

.m-content-auth {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 800px;
}

.p-content-brand {
	max-width: 100%;
	width: 82%;
}

.m-auth-title,
.m-auth-descript {
	color: #d0d2d6;
	text-align: start;
}
.m-auth-title {
	font-size: 24px;
}

.form-group label,
.a-label {
	font-size: 0.857rem;
}

.form-group input {
	color: #d0d2d6;
}

.m-form-input {
	background-color: transparent !important;
	border: 1px solid #404656;
	border-radius: 0.25rem;
}

.m-form-input[id="password"] {
	border-right: none;
}

.m-form-input:focus {
	color: #d0d2d6;
	border-color: #7367f0;
	box-shadow: inherit !important;
}
.eye-content {
	border: 1px solid #404656;
	border-radius: 0 0.25rem 0.25rem 0;
	border-left: none;
	cursor: pointer;
}
.input-group:focus-within .input-group-append .eye-content {
	border-color: #7367f0;
}

.a-btn-submit {
	border-color: #7367f0 !important;
	background-color: #7367f0 !important;
	color: #fff !important;
}
.a-btn-submit:not(:disabled):not(.disabled):active:focus {
	box-shadow: none !important;
}
.a-btn-submit:hover:not(.disabled):not(:disabled) {
	box-shadow: 0 8px 25px -8px #7367f0;
	cursor: pointer;
}
.text-custom {
	color: #d0d2d6;
}
.brand-text {
	line-height: 1;
	font-family: "Montserrat", sans-serif;
	font-size: 24px;
	font-weight: 700;
}
.box-dot {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	outline: 1px solid #7367f0;
	border: 2px solid #283046;
}
.box-check-target {
	cursor: pointer;
}
.box-check:checked + .box-check-target .box-dot {
	background-color: #7367f0;
}

@media screen and (max-width: 90em) {
	.p-content-right {
		width: 40vw;
		padding: 3rem;
	}
	.m-content-auth {
		max-width: 500px;
		margin: 0 auto;
	}
}

@media screen and (max-width: 62em) {
	.top-brand {
		left: 50%;
		transform: translateX(-50%);
	}
	.p-content-left {
		display: none;
	}
	.p-content-right {
		width: 100%;
		padding: 2rem 15%;
	}
}

@media screen and (max-width: 40em) {
	.p-content-right {
		padding: 2rem;
	}
}
.txt-error {
	color: var(--color-warning);
	font-weight: 400;
}
.border-error {
	.m-form-input,
	.eye-content {
		border-color: var(--color-warning) !important;
	}
}
</style>
